import FinanceApi from '@/services/FinanceApi';

const store = async (data) => {
    return FinanceApi.post('/certificate/payments', data);
}

const deletePayment = async (id) => {
    return FinanceApi.delete('/certificate/payments/'+id);
}
export default {
    store,
    deletePayment
}
