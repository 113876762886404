<template>
    <div ref="container">
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <div v-if="data != null && data.customer != null">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('student_number') }}:</label>{{ data.customer.number }}
                                </div>
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('national_number') }}:</label>{{ data.customer.national_id }}
                                </div>
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('name_surname')
                                }}:</label>{{ data.customer.name }}
                                    {{ data.customer.surname }}
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">
                                        {{ $t('student_group') }}:
                                    </label>
                                    <div v-if="checkPermission('customer_patch')" style="display: inline">
                                        <multi-selectbox :multiple="false" :allow-empty="false"
                                            :options="[{ value: 'TR', text: 'TR' }, { value: 'YU', text: 'YU' }]"
                                            v-on:optionSelected="customerGroupChanged"
                                                         style="width: 80px"
                                            v-model="data.customer.group"></multi-selectbox>
                                    </div>
                                    <div v-else>
                                        {{ data.customer.group }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="data && data.certificate_applications">
                <div v-for="plan in data.certificate_applications" v-bind:key="plan.id">
                <h6 class="text-uppercase mb-4">{{ getLocaleText(plan.certificate, 'name').toUpper() }}</h6>
                <b-table bordered striped responsive :items="[plan]" :fields="computedPriceFields(plan)"
                         class="mb-4 table-dropdown no-scrollbar border rounded">
                    <template #cell(price)="row">
                        <div
                            :class="row.item.is_cash == false && row.item.payments.length > 0 ? 'badge badge-danger' : ''">
                            {{ row.item.price | formatToCurrency(row.item.currency) }}
                        </div>
                    </template>
                    <template #cell(cash_price)="row">
                        <div :class="row.item.is_cash ? 'badge badge-danger' : ''">
                            {{ row.item.cash_price | formatToCurrency(row.item.currency) }}
                        </div>
                    </template>
                </b-table>
                <b-table v-if="plan.payments.length > 0" bordered striped responsive :items="plan.payments"
                         :fields="paymentFields" :tbody-tr-class="paymentRowClass" show-empty
                         class="mb-5 table-dropdown no-scrollbar border rounded">
                    <template #cell(due_date)="row">
                        <div v-if="row.item.parent_id != null">
                        </div>
                        <div v-else>
                            {{ row.item.due_date | dateFormat }}
                        </div>
                    </template>
                    <template #cell(amount)="row">
                                <span v-if="row.item.parent_id == null">{{
                                        row.item.amount | formatToCurrency(plan.currency)
                                    }}</span>
                    </template>
                    <template #cell(payment_type_name)="row">
                        {{ row.item.payment_type.name }}
                        <span v-if="row.item.is_cancelled" class="badge badge-danger right">{{toUpperCase('cancelled') }}</span>
                    </template>
                    <template #cell(dropdownTable)="row">
                        <div class="d-flex">
                            <i class="ri-information-fill ri-2x" v-if="row.item.explanation" style="font-size: 16px"
                               v-b-tooltip.hover :title="row.item.explanation"></i>
                            <b-button v-if="!row.item.is_paid && !row.item.is_cancelled" class="mr-1"
                                      variant="outline-primary" size="xs" @click="doPayment(row.item, plan)">{{
                                    $t('do_payment') | toUpperCase }}
                            </b-button>

                            <b-button
                                v-if="row.item.is_paid && row.item.payment_type == 'wire'"
                                class="mr-1" variant="outline-primary" size="xs" @click="editAmount(row.item)">{{
                                    $t('edit') | toUpperCase }}
                            </b-button>

                            <b-button v-if="!row.item.is_paid && !row.item.is_cancelled" class="mr-1"
                                      variant="outline-danger" size="xs" @click="cancelPayment(row.item.id)">{{
                                    $t('cancel') | toUpperCase }}
                            </b-button>

                            <b-button v-if="!row.item.ref_id && checkPermission('payment_delete')"
                                      class="text-danger" variant="link" size="xs" @click="deletePayment(row.item.id)">
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </div>
                    </template>
                    <template slot="bottom-row">
                        <td>
                                    <span class="badge badge-danger" style="width: 100%">{{
                                            toUpperCase(plan.is_cash ? 'cash' : 'with_installment')
                                        }}</span>
                        </td>
                        <td></td>
                        <td><b>
                            {{ plan.payments_sum_amount | formatToCurrency(plan.currency) }}</b></td>
                        <td><b>{{ plan.paid | formatToCurrency(plan.currency) }}</b></td>
                        <td colspan="4"><b>{{ $t('balance') }}:</b> <b
                            :class="plan.balance < 0 ? 'text-danger' : ''">{{
                                plan.balance | formatToCurrency(plan.currency)
                            }}</b>
                        </td>
                    </template>
                </b-table>
                <div class="row">
                    <div class="col-12 d-flex align-items-center mb-4">
                        <div class="row">
                            <div class="col-auto">
                                <b-button variant="outline-primary mr-3" @click="addPayment(plan)"><span
                                    class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                        $t('add_payment').toUpper() }}</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <CommonModal id="addPaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="addPaymentForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="is_cash" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cash_or_installed')">
                                        <b-form-radio v-model="addPaymentForm.is_cash" :value="1">
                                            <span>{{ $t('cash') }}</span>
                                        </b-form-radio>
                                        <b-form-radio v-model="addPaymentForm.is_cash" :value="0">
                                            <span>{{ $t('with_installment') }}</span>
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                                <b-form-input type="number" step=".01"
                                                              v-model="addPaymentForm.amount"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                                <currency-selectbox :validateError="errors[0]"
                                                                    v-model="addPaymentForm.currency">
                                                </currency-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency_rate" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                                <b-form-input type="number" step=".0001"
                                                              v-model="addPaymentForm.currency_rate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="period_id" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('period') }}</label>
                                                <payment-periods-selectbox :validate-error="errors[0]"
                                                                           v-model="addPaymentForm.period_id"></payment-periods-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('due_date') }}</label>
                                                <b-form-input type="date"
                                                              v-model="addPaymentForm.due_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="payment_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                                <b-form-input type="date"
                                                              v-model="addPaymentForm.payment_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                                <b-form-input v-model="addPaymentForm.explanation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="payment_method" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_method')">
                                        <multi-selectbox :multiple="false"
                                                         :options="[{ text: $t('wire'), value: 'wire' }, { text: $t('credit_card'), value: 'credit_card' }]"
                                                         v-model="addPaymentForm.payment_method" :validate-error="errors[0]">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="addPaymentForm.bank_id"
                                                        ref="otherPaymentBank">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12"
                                 v-if="addPaymentForm.payment_method && addPaymentForm.payment_method == 'wire'">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                                                 :is_bank_required="true" :bank_id="addPaymentForm.bank_id"
                                                                 :currency="addPaymentForm.currency"
                                                                 v-model="addPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                 v-if="addPaymentForm.payment_method && addPaymentForm.payment_method == 'credit_card'">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="payment_type_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('payment_type')">
                                                <payment-types-selectbox :validate-error="errors[0]" type="credit_card"
                                                                         :is_bank_required="true" :bank_id="addPaymentForm.bank_id"
                                                                         v-model="addPaymentForm.payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="provision" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                                <b-form-input type="number"
                                                              v-model="addPaymentForm.related_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="terminalOptions">
                                        <ValidationProvider name="terminal" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('terminal')">
                                                <b-form-select :options="terminalOptions"
                                                               v-model="addPaymentForm.account_number"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="card_no" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('card_no')">
                                                <b-form-input autocomplete="false" v-mask="'####-****-****-####'"
                                                              v-model="addPaymentForm.related_info"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePayment" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="paymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="paymentForm">
                        <div class="row" v-if="paymentForm">
                            <div class="col-12" v-if="paymentForm.payments_sum_amount === 0">
                                <ValidationProvider name="is_cash" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cash_or_installed')">
                                        <b-form-radio v-model="paymentForm.is_cash" :value="1">
                                            <span>{{ $t('cash') }}</span>
                                        </b-form-radio>
                                        <b-form-radio v-model="paymentForm.is_cash" :value="0">
                                            <span>{{ $t('with_installment') }}</span>
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="paymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial && paymentForm.show_transfer_next_period">
                                <ValidationProvider name="transfer_next_period" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transfer_next_period') }}</label>
                                        <b-form-checkbox v-model="paymentForm.transfer_next_period"></b-form-checkbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="!allowPartial">
                                <ValidationProvider name="amount" rules="required">
                                    <input type="hidden" v-model="paymentForm.amount" />
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox v-model="paymentForm.currency" :validate-error="errors[0]">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required" ref="currencyRate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="paymentForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="paymentForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="paymentForm.bank_id"
                                            :currency="paymentForm.currency" :is_currency_required="true"
                                            v-model="paymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required" ref="paymentFormPaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="paymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" ref="paymentFormPaymentDate"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="paymentForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="paymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePayment" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="wireModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="wireForm">
                        <div class="row" v-if="wireForm && wireForm.parent_id != null">
                            <div class="col-12 col-sm-4" v-if="allowPartial">
                                <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input type="number" step=".01" v-model="wireForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="allowPartial && wireForm.show_transfer_next_installments">
                                <ValidationProvider name="transfer_next_period" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transfer_next_installments') }}</label>
                                        <b-form-checkbox v-model="wireForm.transfer_next_installments"></b-form-checkbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="!allowPartial">
                                <ValidationProvider name="amount" rules="required">
                                    <input type="hidden" v-model="wireForm.amount" />
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-4">
                                <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox :validateError="errors[0]" v-model="wireForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-4">
                                <ValidationProvider name="currency_rate" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input type="number" step=".0001"
                                            v-model="wireForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="payment_date" rules="required" ref="wirePaymentDate"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input type="date" v-model="wireForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="transaction_date" rules="required" ref="paymentFormPaymentDate"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                        <b-form-input type="date" v-model="wireForm.transaction_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="wireForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                            :is_bank_required="true" :bank_id="wireForm.bank_id"
                                            :currency="wireForm.currency" :is_currency_required="true"
                                            v-model="wireForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input v-model="wireForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveWire" variant="primary">{{
                                    $t('save')
                                }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="vinovModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('vinov_instructions').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <VinovInstructions v-if="data && data.customer && showVinov" :customer_id="data.customer.id"
                        :student_program_id="student_program_id" :registration_id="registration_id"
                        :payment-plans="data.payment_plans">

                    </VinovInstructions>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import CommonModal from '@/components/elements/CommonModal';
import CustomerService from '@/services/CustomerService';
import PaymentService from '@/services/PaymentService';
import OtherPaymentService from "@/services/OtherPaymentService";
import PaymentPlanService from '@/services/PaymentPlanService';
import DocumentService from '@/services/DocumentService';
import ScholarshipService from '@/services/ScholarshipService';
import PaymentTypesSelectbox from '@/components/interactive-fields/PaymentTypesSelectbox';
import PaymentPeriodsSelectbox from '@/components/interactive-fields/PaymentPeriodsSelectbox';
import ScholarshipTypesSelectbox from '@/components/interactive-fields/ScholarshipTypesSelectbox';
import BankSelectbox from '@/components/interactive-fields/BankSelectbox';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PaymentProductsSelectbox from '@/components/interactive-fields/PaymentProductsSelectbox';
import RefundService from '@/services/RefundService';
import moment from 'moment';
import StudentService from '@/services/StudentService';
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import CurrencySelectbox from '@/components/interactive-fields/CurrencySelectbox';
import PaymentApprovalExceptionService from '@/services/PaymentApprovalExceptionService';
import PilotageInvoiceService from '@/services/PilotageInvoiceService';
import CustomerPriceService from "@/services/CustomerPriceService";
import CurrencyRateService from "@/services/CurrencyRateService";
import qs from "qs";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ProformaInvoiceList from "@/modules/proformaInvoice/page/List";
import VinovInstructions from "@/modules/vinovInstructions/page/List";
import CustomerNoteList from "@/modules/customerNotes/page/List";
import ProformaInvoice from "@/services/ProformaInvoiceService";
import StudentProgramService from "@/services/StudentProgramService";
import documentData from '@/modules/students/data/Documents';
import {mapGetters} from "vuex";
import CertificatePaymentService from "@/services/CertificatePaymentService";

export default {
    name: 'Payments',
    props: {
        receipt_number: {
            type: String,
            default: '0'
        }
    },
    components: {
        AcademicYearsSelectbox,
        Header,
        HeaderMobile,
        CurrencySelectbox,
        ParameterSelectbox,
        MultiSelectbox,
        PaymentProductsSelectbox,
        AppLayout,
        CommonModal,
        PaymentPeriodsSelectbox,
        ScholarshipTypesSelectbox,
        PaymentTypesSelectbox,
        BankSelectbox,
        ProformaInvoiceList,
        ValidationProvider,
        ValidationObserver,
        CustomerNoteList,
        VinovInstructions
    },
    data() {
        return {
            number: this.$route.params.number,
            data: null,
            wireForm: null,
            selectedPaymentPlan: null,
            selectedPaymentRow: null,
            allowPartial: true,
            paymentForm: {transaction_date:null},
            addPaymentForm: {},
            terminalOptions: null,
            disabled: false,
            updating: false,
            size: 'lg',
            selected: null,
            CommonModalMode: false,
            checkSpinner: false,
            paymentControl: false,
            clicked: false,
            checkPayment: false,
            file1: null,
            printId: '0',
            printOptions: null,
            date: new Date(),
            showVinov: false,

            priceFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'gross_price',
                    label: this.toUpperCase('gross_price'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'discount_amount',
                    label: this.toUpperCase('discount_amount'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'price',
                    label: this.toUpperCase('with_installment'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'cash_price',
                    label: this.toUpperCase('cash'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'certificate.start_date',
                    label: this.toUpperCase('due_date'),
                    sortable: true,
                    formatter: this.formatDate
                },
                { key: 'dropdownTable', label: '#', tdClass: 'width-100' }
            ],
            paymentFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount_to_be_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'amount_paid',
                    label: this.toUpperCase('amount_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'payment_type_name', label: this.toUpperCase('payment_type') },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('transaction_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'dropdownTable', label: '#', thClass: 'width-150' }
            ],
            actions: null,
            user: {}
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        'paymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'paymentForm.currency'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'wireForm.payment_date'(newVal) {
            this.setCurrencyRate(this.wireForm);
        },
        'wireForm.currency'(newVal) {
            this.setCurrencyRate(this.wireForm);
        },
        'paymentForm.amount'(newVal){
            this.$set(this.paymentForm, 'show_transfer_next_period', false);
            if(this.selectedPaymentPlan.currency != this.paymentForm.currency){
                return;
            }
            if(newVal-this.selectedPaymentPlan.net_installments_balance>1){
                this.paymentForm.show_transfer_next_period=true;
                this.$set(this.paymentForm, 'show_transfer_next_period', true);
            }
            else {
                this.$set(this.paymentForm, 'transfer_next_period', false);
            }
        },
        'wireForm.amount'(newVal){
            this.$set(this.wireForm, 'show_transfer_next_installments', false);
            if(this.selectedPaymentRow.currency != this.wireForm.currency){
                return;
            }
            if(newVal-this.selectedPaymentRow.balance>0.1){
                this.wireForm.show_transfer_next_installments=true;
                this.$set(this.wireForm, 'show_transfer_next_installments', true);
            }
            else {
                this.$set(this.wireForm, 'transfer_next_installments', false);
            }
        }
    },
    methods: {
        setCurrencyRate(obj) {
            var date = null;
            if (typeof obj.payment_date != 'undefined') {
                date = obj.payment_date;
            }
            if (typeof obj.refund_date != 'undefined') {
                date = obj.refund_date;
            }

            if (!obj || obj.currency == 'TRY' || !date) {
                return;
            }
            CurrencyRateService.getByDate(date, obj.currency)
                .then(response => {
                    if (response.data.data && response.data.data.banknote_selling) {
                        obj.currency_rate = response.data.data.banknote_selling;
                    }
                });
        },
        loadData() {
            CustomerService.getCertificatePayments(this.number).then(this.dataCallback).catch(e => {
                this.showErrors(e);
            });
        },
        dataCallback(response) {
            this.data = response.data.data;
            this.actions = [
                {
                    text: this.$t('vinov_instructions'),
                    permission: 'vinovinstruction_index',
                    callback: () => {
                        this.showVinov = true;
                        this.$bvModal.show('vinovModal');
                    },
                    show: () => {
                        return this.data.customer.group == 'TR';
                    }
                }
            ];
        },
        updateExplanation(id, explanation) {
            this.updating = true;
            PaymentPlanService.storeExplanation(id, explanation)
                .then(response => {
                    this.updating = false;
                })
                .catch(e => {
                    this.updating = false;
                });
        },
        upload(customer_id, period_id, period) {
            const formData = new FormData();
            formData.append('customer_id', customer_id);
            formData.append('period_id', period_id);
            formData.append('file', this.file1);

            DocumentService.storeDocument(formData)
                .then(response => {
                    this.data.documents[period] = [response.data.data];
                })
                .catch(error => {
                    this.showErrors(error);
                })
                .finally(() => {
                    this.file1 = null;
                });
        },
        download(uuid, fileName) {
            DocumentService.downloadDocument(uuid)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const data = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = data;
                    link.download = fileName;
                    link.click();
                    setTimeout(function () {
                        window.URL.revokeObjectURL(data);
                    }, 100);
                })
                .catch(error => {
                    this.showErrors(error);
                });
        },
        deleteDocument(uuid, period) {
            DocumentService.deleteDocumentByUuid(uuid)
                .then(response => {
                    if (response.data.success) {
                        this.data.documents[period][0].files;
                        var filtered = this.data.documents[period][0].files.filter(function (value, index) {
                            return value.uuid != uuid;
                        });
                        this.data.documents[period][0].files = filtered;
                    }
                })
        },
        addPayment(payment_plan) {
            this.selectedPaymentPlan = payment_plan;
            this.allowPartial = true;
            this.$bvModal.show('addPaymentModal');
            this.addPaymentForm = {};
            this.$set(this.addPaymentForm, 'certificate_application_id', payment_plan.id);
            this.$set(this.addPaymentForm, 'payments_sum_amount', payment_plan.payments_sum_amount);
            this.$set(this.addPaymentForm, 'payment_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addPaymentForm, 'transaction_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addPaymentForm, 'currency', payment_plan.currency);
            this.$set(this.addPaymentForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            this.$refs.addPaymentForm.reset();
        },
        async savePayment() {
            const isValid = await this.$refs.addPaymentForm.validate();
            if (isValid) {

                this.addPaymentForm.customer_id=this.data.customer.id;
                CertificatePaymentService.store(this.addPaymentForm)
                    .then(response => {
                        this.addPaymentForm = {};
                        this.$refs.addPaymentForm.reset();
                        this.toast(response.data);
                        this.$bvModal.hide('addPaymentModal');
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.addPaymentForm);
                    });
            }
        },
        async deletePayment(id) {
            this.deleteModal(() => {
                CertificatePaymentService.deletePayment(id)
                    .then(response => {
                        this.loadData();
                        if (response.data.success) {
                            this.toast(response.data);
                        } else {
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        async cancelPayment(id) {
            this.$swal.fire({
                input: 'textarea',
                inputPlaceholder: this.$t('enter_explanation'),
                text: this.$t('are_you_sure_to_cancel'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
                inputValidator: (value) => {
                    if (!value) {
                        return this.$t('enter_explanation')
                    }
                }
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        PaymentService.cancel(id, result.value)
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })

        },
        editPayment(row) {
            this.promissoryForm = {
                id: row.id,
                payment_plan_id: row.payment_plan_id,
                payment_type_id: row.payment_type_id,
                due_date: row.due_date,
                amount: row.amount,
                currency: row.currency,
                currency_rate: row.currency_rate,
                explanation: row.explanation
            };

            if (row.payment_type === 'promissory_note') {
                this.$refs.promissoryModal.$refs.commonModal.show()
            }
        },
        scholarshipTypeChanged(selected) {
            if (!selected || this.scholarShipForm.id > 0) {
                return;
            }
            let item = this.$refs.scholarshipType.getItem(selected);
            if (item != null) {
                if (item.is_rate === true) {
                    this.$set(this.scholarShipForm, 'discount_rate', 0);
                    if (parseFloat(item.discount_rate) > 0) {
                        this.$set(this.scholarShipForm, 'discount_rate', parseFloat(item.discount_rate));
                    }
                } else {
                    this.$set(this.scholarShipForm, 'discount_rate', 0);
                }
            }
        },
        checkApproval() {
            //this.checkSpinner = true
        },
        getPrints(plan) {
            let arr = [{ value: '0', text: 'Yazdır' }];
            var i = 1;
            plan.receipt_numbers.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Makbuz: ' + item.receipt_number + ' / ' + item.date,
                    type: 'receipt',
                    id: item.receipt_number
                });
                i++;
            });
            plan.mail_orders.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Mail Order',
                    type: 'mail_order',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.promissory_notes.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Senet',
                    type: 'promissory_note',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.education_credits.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Eğitim Kredisi',
                    type: 'education_credit',
                    id: item,
                    payment_plan_id: plan.id
                });
                i++;
            });
            plan.printOptions = arr;
            return arr;
        },
        selectPrint(plan) {
            if (plan.printId == '0') {
                return;
            }
            let item = this.getOptionItem(plan.printOptions, plan.printId);
            if (item.type == 'receipt') {
                this.printReceipt(item.id);
            } else if (item.type == 'mail_order') {
                this.printMailOrder(item.payment_plan_id, item.id);
            } else if (item.type == 'promissory_note') {
                this.printPromissory(item.payment_plan_id, item.id);
            } else if (item.type == 'education_credit') {
                this.printEducationCredit(item.payment_plan_id, item.id);
            }
        },
        printReceipt(number) {
            if (number == '0') {
                return;
            }
            PaymentService.getReceipt(number)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },
        doPayment(row, plan) {
            this.selectedPaymentPlan = plan;
            this.allowPartial = true;
            if (row.payment_type === 'promissory_note' && row.payment_type_code !== 'SE00') {
                this.promissoryPaymentForm = null;
                this.promissoryPaymentForm = {
                    parent_id: row.id,
                    payment_plan_id: row.payment_plan_id,
                    payment_type_id: row.payment_type_id,
                    due_date: row.due_date,
                    amount: row.amount,
                    currency: row.currency,
                    currency_rate: row.currency_rate,
                    explanation: row.explanation
                };

                this.promissoryPaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
                this.$refs.promissoryPaymentModal.$refs.commonModal.show()
                this.$refs.promissoryPaymentForm.reset();
            } else if (['promissory_note', 'check', 'mail_order', 'delay', 'education_credit'].includes(row.payment_type)) {
                this.selectedPaymentRow = row;
                if (['promissory_note', 'check'].includes(row.payment_type)) {
                    this.allowPartial = false;
                }
                this.$refs.choosePaymentModal.$refs.commonModal.show()
            } else if (row.payment_type === 'bank_credit') {
                this.selectedPaymentRow = row;
                this.bankCreditPaymentForm = null;
                this.bankCreditPaymentForm = {
                    id: this.selectedPaymentRow.id,
                    payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                    payment_type_id: this.selectedPaymentRow.payment_type_id,
                    explanation: this.selectedPaymentRow.explanation,
                    payment_date: this.selectedPaymentRow.payment_date
                };
                if (row.payment_type_options && row.payment_type_options.allow_partial === true) {
                    this.bankCreditPaymentForm.amount = this.selectedPaymentRow.balance;
                }
                this.bankCreditPaymentForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
                this.$refs.bankCreditPaymentModal.$refs.commonModal.show();
            } else if (row.payment_type_code === 'PLTJ') {
                this.selectedPaymentRow = row;
                this.$refs.choosePaymentModal.$refs.commonModal.show()
            }
            this.$set(this.selectedPaymentRow, 'currency_rate', this.data.currencies[plan.currency]);
        },
        wirePayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.wireForm = null;
            this.wireForm = {
                parent_id: this.selectedPaymentRow.id,
                payment_plan_id: this.selectedPaymentRow.payment_plan_id,
                explanation: this.selectedPaymentRow.explanation,
                payment_date: this.selectedPaymentRow.payment_date,
                amount: this.selectedPaymentRow.balance,
                currency: this.selectedPaymentRow.currency,
                currency_rate: this.data.currencies[this.selectedPaymentRow.currency]
            };

            this.wireForm.payment_date = moment(new Date()).format('YYYY-MM-DD');
            this.wireForm.transaction_date = moment(new Date()).format('YYYY-MM-DD');
            this.$refs.wireModal.$refs.commonModal.show();
        },
        async saveWire() {
            const isValid = await this.$refs.wireForm.validate();
            if (isValid) {

                PaymentService.storeWire(this.wireForm)
                    .then(response => {
                        this.$refs.wireModal.$refs.commonModal.hide()
                        this.loadData();
                        this.wireForm = {};
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.wireForm);
                    });
            }
        },
        creditCardPayment() {
            this.$refs.choosePaymentModal.$refs.commonModal.hide();
            this.$router.push('/payments/collection/' + this.selectedPaymentRow.payment_plan_id + '/' + this.selectedPaymentRow.id);
        },
        paymentRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.parent_id) return 'sub-payments'
        },
        editDueDate(row) {

            let pricesSelect = '<select id="payment_plan_price_id">'
            let $this = this;
            row.all_prices.forEach(function (price) {
                let text = $this.formatToCurrency(price.price, null, price);
                if (price.start_date) {
                    text += ' (' + $this.formatDate(price.start_date) + ' - ' + $this.formatDate(price.end_date);
                }
                pricesSelect += '<option value="' + price.id + '" ' + (price.id == row.price_id ? 'selected' : '') + '>' + text + '</option>';

            });
            pricesSelect += '</select>';

            let cashDiscounts = [{ text: "Taksitli", value: 0 }];
            if (this.data.product_discounts) {
                Object.values(this.data.product_discounts).forEach(function (discount) {
                    if (discount.cash_discount_rate > 0 && discount.product_id == row.product.id && discount.period_id == row.period_id) {
                        cashDiscounts.push({ text: "Peşin %" + discount.cash_discount_rate + " (" + discount.start_date + "-" + discount.end_date, value: discount.cash_discount_rate });
                    }
                });
            }
            let cashDiscountSelect = '<select id="payment_plan_cash_discount_rate">'
            cashDiscounts.forEach(function (cashDiscount) {
                cashDiscountSelect += '<option value="' + cashDiscount.value + '" ' + (cashDiscount.value == row.cash_discount_rate ? 'selected' : '') + '>' + cashDiscount.text + '</option>';
            });
            cashDiscountSelect += '</select>';
            this.$swal.fire({
                title: this.$t('edit_due_date').toUpper(),
                html: '<input id="payment_plan_due_date" type="date" value="' + row.due_date + '"><br><br>' + pricesSelect + '<br><br>' + cashDiscountSelect,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        'due_date': document.getElementById('payment_plan_due_date').value,
                        'cash_discount_rate': document.getElementById('payment_plan_cash_discount_rate').value,
                        'new_price_id': document.getElementById('payment_plan_price_id').value,
                        //'early_discount_rate':document.getElementById('payment_plan_early_discount_rate').value,
                    };
                },
                icon: null,
                showCancelButton: true,
                confirmButtonText: this.$t('save'),
                cancelButtonText: this.$t('cancel')
            })
                .then((result) => {
                    if (result.isConfirmed && result.value) {
                        PaymentPlanService.patch(row.id, result.value)
                            .then(response => {
                                this.loadData();
                                if (response.data.success) {
                                    this.toast(response.data);
                                } else {
                                    this.$toast.error(this.$t('api.' + response.data.message));
                                }
                            })
                            .catch(error => {
                                this.showErrors(error);
                            });
                    }
                })
        },
        forceRerender(renderComponent) {
            // Remove my-component from the DOM
            renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                renderComponent = true;
            });
        },
        customerGroupChanged(val) {
            CustomerService.patch(this.data.customer.id, { group: val.value })
                .then(response => {
                    this.toast(response.data);
                }).catch(e => {
                    this.showErrors(e);
                });
        },
        computedPriceFields(plan) {
            return this.priceFields.filter((field) => {
                return true;
            });
        },

        showDocumentModal() {
            this.$refs.DocumentModal.$refs.commonModal.show()
        }
    },
    computed: {
        ...mapGetters(['auth/getActiveRole']),
        computeDocuments() {
            let documents = [];

            if (this.user && this.user.id && this.user.student_number) {
                documents = documentData(this.user)
            }
            return documents;
        }
    }
}
</script>
<style scoped>
*>>>.sub-payments {
    background-color: #e4f5ee !important;
    font-weight: 500;
}

*>>>button {
    white-space: nowrap;
}

*>>>.table-bordered thead tr th {
    background-color: #005baa !important;
    color: #ffffff;
}

*>>>.btn-xs {
    line-height: 24px !important;
    height: 24px !important;
}
</style>
